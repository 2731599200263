import React from 'react';
import '../App.css';

const Home = () => {
	return (
		<div className='body-section'>
			<h1>PORZĄDKOWANIE GROBÓW</h1>
			<p>
				Pamięć o naszych bliskich jest równie ważna, jak życie którego wspólnie doświadczyliśmy Jeśli nie masz czasu,
				mieszkasz daleko od grobu swoich bliskich lub z innych powodów nie możesz zadbać o pomnik daj znać, a chętnie w
				Państwa imieniu pomogę.
			</p>
			<p>OBSŁUGIWANE SĄ WSZYSTKIE CMENTARZE NA TERENIE BYDGOSZCZY</p>
		</div>
	);
};

export default Home;
