import React from 'react';
import '../App.css';
import location from '../images/location.svg';
import phone from '../images/phone.svg';
import mail from '../images/mail.svg';

const Contact = () => {
	return (
		<div className='body-section-contact'>
			<h1>Kontakt</h1>
			<div className='contact-list'>
				<ul>
					<li>
						<div className='icon'>
							<img src={location} alt='Ikona lokacji' />
						</div>
						<div className='info'>
							<p>
								<a href='https://maps.app.goo.gl/NUBvxRgavgEEJ68N7' target='_blank' rel='noreferrer'>
									ul. Akademicka 8D, Bydgoszcz
								</a>
							</p>
						</div>
					</li>
					<li>
						<div className='icon'>
							<img src={phone} alt='Ikona telefonu' />
						</div>
						<div className='info'>
							<p>
								<a href='tel:+48794635084'>794 635 084</a>
							</p>
						</div>
					</li>
					<li>
						<div className='icon'>
							<img src={mail} alt='Ikona emaila' />
						</div>
						<div className='info'>
							<p>
								<a href='mailto:porzadkowaniegrobowbydgoszcz@wp.pl'>porzadkowaniegrobowbydgoszcz@wp.pl</a>
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div className='text'>
				<p>
					Jeżeli nie mogę odebrać, proszę państwa o cierpliwość, na pewno oddzwonię. Można również pozostawić wiadomość
					na skrzynce bądź sms i złożyć zlecenie. Proszę o wskazanie zakresu usług, podanie numeru kwatery, nazwiska,
					które znajdują się na płycie i cmentarz.
				</p>
			</div>
		</div>
	);
};

export default Contact;
