import React from 'react';
import '../App.css';

const Pricing = () => {
	return (
		<div className='body-section-services'>
			<h1>Cennik</h1>
			<div className='price-list'>
				<ul>
					<li>
						<span className='service-name'>Grób dziecięcy</span>
						<span className='line'></span>
						<span className='service-price'>70 PLN</span>
					</li>
					<li>
						<span className='service-name'>Grób pojedynczy</span>
						<span className='line'></span>
						<span className='service-price'>100 PLN</span>
					</li>
					<li>
						<span className='service-name'>Grób podwójny</span>
						<span className='line'></span>
						<span className='service-price'>140 PLN</span>
					</li>
					<li>
						<span className='service-name'>Grób rodzinny</span>
						<span className='line'></span>
						<span className='service-price'>160 PLN</span>
					</li>
				</ul>
			</div>
			<div className='pricing-text'>
				<p>Mycie grobu w zależności od materiału z jakiego jest wykonany oraz wielkości płyty. Cena od 20 do 40 PLN</p>
				<p>Zapalenie znicza. Cena uzależniona jest od ilości, wielkości i czasu palenia.</p>
				<p>Odchwaszczanie. Cena od 50 do 100 PLN</p>
			</div>
		</div>
	);
};

export default Pricing;
