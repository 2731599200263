import React from 'react';
import '../App.css';

const Services = () => {
	return (
		<div className='body-section-services'>
			<h1>Oferowane usługi</h1>
			<div className='list'>
				<ul>
					<li>
						<span style={{ fontWeight: 'bold' }}>Porządkowanie grobu i jego najbliższego otoczenia</span> z myciem
						Usuwane są z powierzchni grobu stare znicze, kwiaty, kurz liście, gałęzie drzew. Przed wykonaniem usługi
						oraz bezpośrednio po jej wykonaniu robione są zdjęcia i wysyłane na wskazany adres email.
					</li>
					<li>
						<span style={{ fontWeight: 'bold' }}>Porządkowanie grobu i jego najbliższego otoczenia z myciem</span>{' '}
						Usuwane są z powierzchni grobu stare znicze, kwiaty, kurz liście, gałęzie drzew. Mycie odbywa się przy
						użyciu środków specjalnie przeznaczonych do wykonania takiej usługi. Przed wykonaniem usługi oraz
						bezpośrednio po jej wykonaniu robione są zdjęcia i wysyłane na wskazany adres email.
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Services;
