import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './pages/Home.js';
import Pricing from './pages/Pricing.js';
import Services from './pages/Services.js';
import Contact from './pages/Contact.js';

const App = () => {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/pricing' element={<Pricing />} />
				<Route path='/services' element={<Services />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
