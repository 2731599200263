import React from 'react';
import '../App.css';
import location from '../images/location.svg';
import phone from '../images/phone.svg';
import mail from '../images/mail.svg';

const Footer = () => {
	return (
		<div className='footer'>
			<ul className='footer-list'>
				<li>
					<span>
						<img src={location} alt='Ikona lokacji' />
						<a href='https://maps.app.goo.gl/NUBvxRgavgEEJ68N7' target='_blank' rel='noreferrer'>
							ul. Akademicka 8D, Bydgoszcz
						</a>
					</span>
				</li>
				<li>
					<span>
						<img src={phone} alt='Ikona emaila' />
						<a href='tel:+48794635084'>794 635 084</a>
					</span>
				</li>
				<li>
					<span>
						<img src={mail} alt='Ikona emaila' />
						<a href='mailto:porzadkowaniegrobowbydgoszcz@wp.pl'>porzadkowaniegrobowbydgoszcz@wp.pl</a>
					</span>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
