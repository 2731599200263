import React from 'react';
import '../App.css';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<div className='header'>
			<Link to='/'>
				<img className='logo' src={Logo} alt='Logo' />
			</Link>
			<span className='headerline'></span>
			<ul>
				<li>
					<Link to='/'>Strona główna</Link>
				</li>
				<li>
					<Link to='/services'>Usługi</Link>
				</li>
				<li>
					<Link to='/pricing'>Cennik</Link>
				</li>
				<li>
					<Link to='/contact'>Kontakt</Link>
				</li>
			</ul>
		</div>
	);
};

export default Header;
